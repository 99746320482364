import * as React from "react";
import {
  FormContextType,
  IconButtonProps as OriginalIconButtonProps,
  RJSFSchema,
  StrictRJSFSchema,
  TranslatableString,
} from "@rjsf/utils";
import { ButtonNew } from "@theloopco/loopos-ds-sdk";

interface CustomIconButtonProps<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
> extends OriginalIconButtonProps<T, S, F> {
  app?: any;
}

export default function AddButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ uiSchema, registry, app, ...props }: CustomIconButtonProps<T, S, F>) {
  const { translateString } = registry;

  const { onClick } = props;

  return (
    <ButtonNew
      app={app}
      iconPosition="left"
      icon="fa-regular fa-plus"
      text={translateString(TranslatableString.AddItemButton)}
      handleClick={onClick as unknown as () => void}
      railsIcon
      {...props}
    ></ButtonNew>
  );
}
