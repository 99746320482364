import '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

const { concat, uniq } = require('lodash');

export const application = Application.start();

export const controllerRequireContext = require.context('../controllers', true, /_controller\.js$/);

export var componentRequireContext = require.context('../components', true);
export var ReactRailsUJS = require('react_ujs');

export const combineContexts = (...contexts) => {
  function webpackContext(req) {
    // Find the first match and execute
    const matches = contexts
      .map((context) => context.keys().indexOf(req) >= 0 && context)
      .filter((a) => a);

    return matches[0] && matches[0](req);
  }

  webpackContext.keys = () =>
    uniq(
      concat.apply(
        null,
        contexts.map((context) => context.keys()),
      ),
    );
  return webpackContext;
};

export function mountComponents() {
  document.addEventListener('DOMContentLoaded', () => {
    const findComponents = (childNodes, testFn, nodes = []) => {
      for (let child of childNodes) {
        if (child.childNodes.length > 0) {
          nodes = findComponents(child.childNodes, testFn, nodes);
        } else if (testFn(child)) {
          nodes = nodes.concat([child]);
        }
      }
      return nodes;
    };

    const mountComponents = (nodes) => {
      for (let child of nodes) {
        const className = child.getAttribute(ReactRailsUJS.CLASS_NAME_ATTR);
        if (className) {
          // Taken from ReastRailsUJS as is.
          const constructor = ReactRailsUJS.getConstructor(className);
          const propsJson = (child).getAttribute(ReactRailsUJS.PROPS_ATTR);
          const props = propsJson && JSON.parse(propsJson);

          // Improvement:
          // Was this component already rendered? Just hydrate it with the props coming in.
          // This is currently acceptable since all our components are expected to be reset
          // on page navigation.
          const component = React.createElement(constructor, props);
          ReactDOM.render(component, child);
        }
      }
    };

    const callback = function (mutationsList, observer) {
      const start = performance.now();
      // console.log("ReactRails: Mutation callback started...", mutationsList)

      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (mutation.addedNodes.length > 0) {
            const mountableNodes = findComponents(mutation.addedNodes, (child) => {
              return !!child.dataset?.reactClass;
            });

            mountComponents(mountableNodes);
          }
        }
      }

      // console.log("ReactRails: Mutation callback complete.", performance.now() - start)
    };

    const observer = new MutationObserver(callback);

    // console.log("ReactRails: Start mutation observer...")
    observer.observe(document, { childList: true, subtree: true });
  });
}
