import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
  }

  changeSelect(e) {
    const value = e.target.getAttribute('data-value')
    const idToSelect = this.data.get('form')
    document.querySelector(`[data-impact-field="${idToSelect}"]`).value = value
    document.querySelector(`[data-form="${idToSelect}"]`).requestSubmit()
  }
}