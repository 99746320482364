import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["overlay", "icon"];

  toggleOverlay() {
    this.overlayTarget.classList.toggle("active");
    this.iconTarget.classList.toggle("inverted");
  }
}
