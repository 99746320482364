import React, { useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import { ariaDescribedByIds, FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { ColorPicker } from "@theloopco/loopos-ds-sdk";

type CustomWidgetProps<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
> = WidgetProps<T, S, F> & {
  options: any
}

export default function ColorWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({
    id,
    placeholder,
    value,
    required,
    disabled,
    autofocus,
    readonly,
    onBlur,
    onFocus,
    onChange,
    options,
  }: CustomWidgetProps<T, S, F>) {    
  const [color, setColor] = useState(value);

  useEffect(() => {
    // NOTE: we only use the # + 6 characters, because we want Hex and the picker sends HexAlpha
    onChange(color?.slice(0, 7))
  }, [color]);

  return (
    <div className='flex'>
      <ColorPicker color={color} setColor={setColor} lang="en" />
    </div>
  );
}
