import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["file", "image", "removeButton", "icon", "text"];

  openFilePicker(event) {
    event.preventDefault();
    this.fileTarget.click();
  }

  preview() {
    const file = this.fileTarget.files[0];

    if (file) {
      const url = URL.createObjectURL(file);
      this.imageTarget.src = url;
      this.imageTarget.onload = () => {
        URL.revokeObjectURL(url);
        this.removeButtonTarget.style.display = "block";
        this.hideUpload();
      };
    }
  }

  removeImage() {
    this.imageTarget.src = "";
    this.fileTarget.value = "";
    this.removeButtonTarget.style.display = "none";
    this.hidePreview();
  }

  hideUpload() {
    this.imageTarget.style.display = "block";
    this.iconTarget.style.display = "none";
    this.textTarget.style.display = "none";
  }

  hidePreview() {
    this.imageTarget.style.display = "none";
    this.iconTarget.style.display = "block";
    this.textTarget.style.display = "block";
  }
}
