import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['action', 'table', 'param', 'globalParam', 'submit', 'modal']

  modalTargetConnected() {
    this.modalTarget.closest('[data-modal-target="modal"]').addEventListener("modal-close", this.clean.bind(this))  
  }

  actionTargetConnected(target) {
    const controller = this
    // event type: submit for <form>, click for <a>
    const eventType = target.nodeName === "FORM" ? "submit" : "click"
    target.addEventListener(eventType, (event) => this.updateAction(controller, target, event))
  }

  updateAction(controller, target, event) {
    const ids = controller.selectedIds
    const globalParams = controller.globalParams

    switch (target.nodeName) {
      case "FORM":
        ids.forEach((id) => {
          const template = document.createElement('template');
          template.innerHTML = ('<input type="hidden" name="id[]" value="' + id + '" /> ').trim();
          target.append(template.content.children[0]);
        })
        globalParams.forEach((fieldTag) => {
          const template = document.createElement('template');
          template.innerHTML = (fieldTag.outerHTML).trim();
          target.append(template.content.children[0]);
        })
        break;
      default:
        const url = new URL(target.href)
        url.searchParams.delete('id[]')
        ids.forEach((id) => url.searchParams.append('id[]', id))
        globalParams.forEach((fieldTag) => url.searchParams.append(fieldTag.getAttribute("name"), fieldTag.getAttribute("value")))
        target.href = url.href
    }
  }

  updateInputValue(event) {
    const form = event.target.closest("form")
    if (form) {
      const dataElement = event.target.closest("[data-value]")
      const attribute = dataElement.dataset.attribute
      const submitValue = dataElement.dataset.value
      const inputElement = form.querySelector("[name='" + attribute + "']")
      const alreadyExists = !!inputElement

      if (alreadyExists) {
        inputElement.value = submitValue
      }
      else {
        const template = document.createElement('template');
        template.innerHTML = ('<input type="hidden" name="' + attribute +'" value="' + submitValue + '" /> ').trim();
        form.append(template.content.children[0]);
      }
      if(this.hasSubmitTarget) this.submitTarget.querySelector("button").disabled = false
    }
  }

  get selectedIds() {
    if (this.hasTableTarget) {
      const checkboxes = this.tableTarget.querySelectorAll("input[type=checkbox]:checked")
      return Array.from(checkboxes).map((checkbox) => checkbox.getAttribute("selected-id"))
    }
    return []
  }

  get globalParams() {
    if (this.hasGlobalParamTarget) {
      return this.globalParamTargets
    }
    return []
  }

  clean() {
    const submitButton = this.submitTarget?.querySelector("button")
    if (submitButton) submitButton.disabled = true
  }
}
