// FIXME: copied from vendor/gems/loop-os-scripts/app/assets/javascripts/custom_code_controller.js
// the old mode of including (bellow) breaks when including more than a handful of controllers
//<%= File.read([Gem.loaded_specs['loopos-scripts'].full_gem_path, 'app/assets/javascripts/', 'custom_code_controller.js'].join('/')) %>
// with a cryptic error message:
// Uncaught Error: Module build failed (from ./node_modules/rails-erb-loader/index.js):
// Error: rails-erb-loader failed with code: 1
//
// We need to figure out how to load the controllers from the gems

import { Controller } from 'stimulus';
import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';

export default class extends Controller {
  static values = {
    code: String,
  };
  static targets = ['codeBlock', 'codeElem'];

  editor;

  connect() {
    if (this.hasCodeBlockTarget) {
      this.editor = ace.edit(this.codeBlockTarget, {
        theme: 'ace/theme/textmate',
        mode: 'ace/mode/ruby',
        value: this.codeBlockTarget.dataset.value,
        showInvisibles: true,
        printMargin: false,
        tabSize: 2,
        readOnly: this.codeBlockTarget.dataset.readOnly,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
      });

      this.editor.on('change', () => {
        this.codeElemTarget.value = this.editor.getValue();
      });
    }
  }

  kindChanged(e) {
    if (this.codeBlockTarget.dataset.persisted === 'false') {
      this.editor.setValue(e.target.selectedOptions[0].dataset.code);
    }
  }
}
