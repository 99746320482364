import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="actions-loading"
export default class extends Controller {

  static targets = ["loading"]

  connect() {
    this.form.addEventListener("submit", this.show.bind(this))
  }

  show() {
    // These two work because Avo uses them. We are not including tailwind in this project.
    setTimeout(() => {
      this.loadingTarget.classList.add("opacity-100")
      this.loadingTarget.classList.remove("opacity-0")
    }, 500)
    this.loadingTarget.style.maxHeight = "300px"
  }

  get form() {
    return this.element.querySelector("form")
  }
}
