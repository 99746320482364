import * as looposSettings from "../../../vendor/gems/loop-os-settings/app/javascript/packs/loopos-settings.js";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const context = require.context("../controllers", true, /_controller\.js$/);
const combinedContext = looposSettings.combineContexts(
  context,
  looposSettings.controllerRequireContext
);

looposSettings.application.load(definitionsFromContext(combinedContext));
