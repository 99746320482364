import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    if (this.hasContentTarget) {
      setTimeout(() => this.removeOnTime(this.contentTarget), 5000)
    }
  }

  removeEl(target) {
    target.remove();
    target.append();
  }

  removeOnTime(content) {
    content.style.transform = 'translateX(120%)';
    setTimeout(() => this.removeEl(this.contentTarget), 1000);
  }

  hide() {
    this.removeOnTime(this.contentTarget)
  }
}