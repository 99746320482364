import * as React from 'react'
import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps as OriginalArrayFieldTemplateProps,
  FormContextType,
  getTemplate,
  getUiOptions,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils'
import { Accordion } from '@theloopco/loopos-ds-sdk'
import { useState } from 'react'

interface CustomArrayFieldTemplateProps<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
> extends OriginalArrayFieldTemplateProps<T, S, F> {
  app?: any
}

export default function ArrayFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: CustomArrayFieldTemplateProps<T, S, F>) {
  const { canAdd, disabled, idSchema, uiSchema, items, onAddClick, readonly, registry, required, schema, title, app } =
    props
  const uiOptions = getUiOptions<T, S, F>(uiSchema)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const ArrayFieldDescriptionTemplate = getTemplate<'ArrayFieldDescriptionTemplate', T, S, F>(
    'ArrayFieldDescriptionTemplate',
    registry,
    uiOptions
  )
  const ArrayFieldItemTemplate = getTemplate<'ArrayFieldItemTemplate', T, S, F>(
    'ArrayFieldItemTemplate',
    registry,
    uiOptions
  )
  const ArrayFieldTitleTemplate = getTemplate<'ArrayFieldTitleTemplate', T, S, F>(
    'ArrayFieldTitleTemplate',
    registry,
    uiOptions
  )
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates

  const addButtonProps = (props?.uiSchema && props?.uiSchema['ui:options']?.addButtonProps) || {}

  return (
    <div>
      <div className="w-full">
        <div className="m-0 w-full p-0">
          <Accordion
            title={uiOptions.title || title}
            variant="settings"
            isOpen={isOpen}
            handleClick={() => setIsOpen(!isOpen)}
          >
            <ArrayFieldDescriptionTemplate
              idSchema={idSchema}
              description={uiOptions.description || schema.description}
              schema={schema}
              uiSchema={uiSchema}
              registry={registry}
            />

            <div className="m-0 w-full p-0">
              {items &&
                items.map(({ key, ...itemProps }: ArrayFieldTemplateItemType<T, S, F>) => (
                  <ArrayFieldItemTemplate key={key} {...itemProps} />
                ))}
              {canAdd && (
                <div className="">
                  <div className="mt-4 mb-2 flex">
                    <div className="w-3/4"></div>
                    <div className="w-1/4">
                      <AddButton
                        app={props?.registry?.formContext?.app}
                        className="array-item-add"
                        onClick={onAddClick}
                        disabled={disabled || readonly}
                        uiSchema={uiSchema}
                        registry={registry}
                        {...(addButtonProps as object)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
