import * as React from 'react'
import {
  canExpand,
  descriptionId,
  FormContextType,
  getTemplate,
  getUiOptions,
  ObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  titleId,
} from '@rjsf/utils'
import { GroupTemplate } from '../GroupTemplate/GroupTemplate'

export default function ObjectFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  description,
  title,
  properties,
  required,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
  disabled,
  readonly,
  registry,
}: ObjectFieldTemplateProps<T, S, F>) {
  const uiOptions = getUiOptions<T, S, F>(uiSchema)
  const TitleFieldTemplate = getTemplate<'TitleFieldTemplate', T, S, F>('TitleFieldTemplate', registry, uiOptions)
  const DescriptionFieldTemplate = getTemplate<'DescriptionFieldTemplate', T, S, F>(
    'DescriptionFieldTemplate',
    registry,
    uiOptions
  )
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates

  const useGroup = idSchema.$id === 'root'

  let groupedProperties = {}
  const groups = (uiOptions?.groups || []) as any[]
  const remainingGroup = groups.find((g) => g.remaining)
  if (useGroup) {
    groups.forEach((group) => {
      typeof group === 'object' && (groupedProperties[group.title] = [])
    })
    properties.forEach((property) => {
      const group = groups?.find((group) => typeof group === 'object' && group.properties?.includes(property.name))
      if (group) {
        groupedProperties[group.title].push(property)
      } else {
        const remainingName = remainingGroup?.title || '_'
        groupedProperties[remainingName] ||= []
        groupedProperties[remainingName].push(property)
      }
    })
  } else {
    groupedProperties = { _: properties }
  }

  return (
    <>
      <style>
        {`
          .accordion_container_settings .accordion_container_settings {
            padding-left: 16px;
          }
        `}
      </style>
      <GroupTemplate name={title} key={idSchema.$id} hidden={!title}>
        {description && (
          <DescriptionFieldTemplate
            id={descriptionId<T>(idSchema)}
            description={description}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />
        )}
        <div className="w-full">
          {Object.keys(groupedProperties).map(
            (group, groupIndex) =>
              groupedProperties[group].length > 0 && (
                <GroupTemplate
                  key={groupIndex}
                  name={group !== '_' ? group : null}
                  initialOpen={groups.find((g) => g.title == group)?.open}
                >
                  {groupedProperties[group].map((element: any, index: number) => (
                    <div
                      key={`${groupIndex}-${index}`}
                      className={`${element.hidden ? 'hidden' : ''} flex mt-4 w-full`}
                    >
                      <div className="w-full"> {element.content}</div>
                    </div>
                  ))}
                </GroupTemplate>
              )
          )}
          {!useGroup && canExpand(schema, uiSchema, formData) ? (
            <div className="flex">
              <div className="ml-auto w-1/4 py-4">
                <AddButton
                  onClick={onAddClick(schema)}
                  disabled={disabled || readonly}
                  className="object-property-expand"
                  uiSchema={uiSchema}
                  registry={registry}
                />
              </div>
            </div>
          ) : null}
        </div>
      </GroupTemplate>
    </>
  )
}
