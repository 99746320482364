// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require algoliasearch/lite
//= require instantsearch.js
//= require instantsearch.js/es/widgets

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "@hotwired/turbo-rails";
import "channels";
import "pages";
import "initializers";
import "flowbite";

import * as looposSettings from "../../../vendor/gems/loop-os-settings/app/javascript/packs/loopos-settings";
import * as looposReact from "./react"
import "../../../vendor/gems/loopos-universal-search/app/assets/javascript/packs/algolia_search.js";

const combinedContext = looposReact.combineContexts(
  looposReact.componentRequireContext,
  looposSettings.componentRequireContext
);

looposReact.ReactRailsUJS.useContext(combinedContext);
looposReact.mountComponents(combinedContext);

Rails.start();
ActiveStorage.start();

document.addEventListener("turbo:before-fetch-request", (event) => {
  if (event.target.dataset["turboStream"] == "false")
    event.detail.fetchOptions.headers["Accept"] = "text/html, application/xhtml+xml"
})
