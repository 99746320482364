import React, { useState } from 'react'
import { Toggle } from '@theloopco/loopos-ds-sdk'

const JsonFormToggler: any = ({ isChecked, handleToggleChange, app }) => {
  return (
    <div className="flex items-center">
      <span className="mr-2 text-[12px] text-normal">Json Mode</span>
      <Toggle onClick={handleToggleChange} small app={app} />
    </div>
  )
}

export default JsonFormToggler
