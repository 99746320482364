import * as React from 'react'
import {
  FieldTemplateProps,
  FormContextType,
  getTemplate,
  getUiOptions,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@theloopco/loopos-ds-sdk'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

export default function FieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  id,
  children,
  displayLabel,
  rawErrors = [],
  errors,
  help,
  description,
  rawDescription,
  classNames,
  style,
  disabled,
  label,
  hidden,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  schema,
  uiSchema,
  registry,
}: FieldTemplateProps<T, S, F>) {
  const uiOptions = getUiOptions(uiSchema)
  const WrapIfAdditionalTemplate = getTemplate<'WrapIfAdditionalTemplate', T, S, F>(
    'WrapIfAdditionalTemplate',
    registry,
    uiOptions
  )
  if (hidden) {
    return <div className="hidden">{children}</div>
  }

  const hasDescription = !!rawDescription && rawDescription.length > 0
  const shownLabel = hasDescription ? rawDescription : label
  const labelText = shownLabel.toLowerCase()

  return (
    <WrapIfAdditionalTemplate
      classNames={classNames}
      style={style}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
      uiSchema={uiSchema}
      registry={registry}
    >
      <div className="block">
        {displayLabel && (
          <div className="flex flex-row items-start items-start gap-1">
            <label
              htmlFor={id}
              className={`mt-2 mb-2 inline-block text-sm text-[#495057] primary-12-medium ${
                rawErrors.length > 0 ? 'text-red-500' : ''
              }`}
            >
              {shownLabel}
              {required ? ' *' : null}
            </label>
            {schema && schema['ui:hint'] ? (
              <Tooltip id={'loopos-seetings-hint'} text={schema['ui:hint']}>
                <div className="flex">
                  <FontAwesomeIcon icon={faCircleInfo} size="xs" color="#495057" style={{ fontSize: '10px' }} />
                </div>
              </Tooltip>
            ) : null}
          </div>
        )}
        {children}
        {/* Hidden, the description is used, if it does not exist, label is used */}
        {false && displayLabel && rawDescription && (
          <small className="mt-1 block">
            <div className={`${rawErrors.length > 0 ? 'text-red-500' : 'text-muted-foreground'}`}>{description}</div>
          </small>
        )}
        {errors}
        {help}
      </div>
    </WrapIfAdditionalTemplate>
  )
}
