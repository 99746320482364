import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['action', 'target']

  autoUpdateSelectValue(event) {
    const form = event.target.closest("form")
    if (form) {
      const dataElement = event.target.closest("[data-value]")
      const attribute = dataElement.dataset.attribute
      const submitValue = dataElement.dataset.value
      const inputElement = form.querySelector("[name='" + attribute + "']")
      const alreadyExists = !!inputElement

      if (alreadyExists) {
        inputElement.value = submitValue
      }
      else {
        const template = document.createElement('template');
        template.innerHTML = ('<input type="hidden" name="' + attribute +'" value="' + submitValue + '" /> ').trim();
        form.append(template.content.children[0]);
      }
      if(this.hasSubmitTarget) this.submitTarget.querySelector("button").disabled = false
      form.submit()
    }
  }
}
