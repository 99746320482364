import {
  FormContextType,
  getSubmitButtonOptions,
  RJSFSchema,
  StrictRJSFSchema,
  SubmitButtonProps as OriginalSubmitButtonProps,
} from "@rjsf/utils";
import * as React from "react";
import { ButtonNew } from "@theloopco/loopos-ds-sdk";

interface CustomSubmitButtonProps<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
> extends OriginalSubmitButtonProps<T, S, F> {
  app?: any;
}

export default function SubmitButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: CustomSubmitButtonProps<T, S, F>) {
  const {
    submitText,
    norender,
    props: submitButtonProps,
  } = getSubmitButtonOptions<T, S, F>(props.uiSchema);

  if (norender) {
    return null;
  }

  const submitBtnProps =
    (props?.uiSchema && props?.uiSchema["ui:options"]?.submitButtonOptions) ||
    {};

  return (
    <div className="flex justify-end mt-4">
      <ButtonNew
        disabled={submitButtonProps?.disabled}
        app={props?.registry?.formContext?.app}
        text={props?.registry?.formContext?.mainBtnText ?? "Save"}
        size="large"
        variant="primary"
        {...(submitBtnProps as object)}
      ></ButtonNew>
    </div>
  );
}
