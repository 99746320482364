import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['dependantDiv']
  static values = {
    url: { type: String, default: '/{value}/select_collection' },
  }

  connect() {
  }

  fetchValues(event) {
    let option = event.currentTarget
    let optionValue = this.pluralize(this.toSnakeCase(option.dataset.value))
    let url = this.formatString(this.urlValue, { value: optionValue })
    let id = (Math.random() + 1).toString(36).substring(7);
    this.dependantDivTarget.setAttribute('id', id);
    let label = this.dependantDivTarget.dataset.label;
    let attribute = this.dependantDivTarget.dataset.attribute;

    console.log(url);
    get(`${url}?target=${id}&attribute=${attribute}&label=${label}`, { responseKind: 'turbo-stream' })
  }

  formatString(str, values) {
    return str.replace(/{(\w+)}/g, function (match, key) {
      return typeof values[key] !== 'undefined' ? values[key] : match;
    });
  }

  toSnakeCase(str) {
    return str
      .replace(/([A-Z])/g, '_$1')
      .toLowerCase()
      .replace(/^_/, '');
  }

  pluralize(str) {
    const pluralRules = [
      { regex: /([^aeiou]y)$/i, replacement: '$1ies' },
      { regex: /(s|sh|ch|x|z)$/i, replacement: '$1es' },
      { regex: /([^aeiou])o$/i, replacement: '$1oes' },
      { regex: /f$/i, replacement: 'ves' },
      { regex: /fe$/i, replacement: 'ves' },
      { regex: /us$/i, replacement: 'i' },
      { regex: /is$/i, replacement: 'es' },
      { regex: /on$/i, replacement: 'a' },
    ];

    for (let rule of pluralRules) {
      if (rule.regex.test(str)) {
        return str.replace(rule.regex, rule.replacement);
      }
    }

    return str + 's';
  }
}
