import * as React from 'react'
import Form from './rjsf'
import { RJSFSchema, UiSchema, ValidatorType } from '@rjsf/utils'
import { CoreAPI } from './Flows/CoreAPI'
import { FormEvent, useState, useEffect } from 'react'
import { evaluateValidator } from './SchemaCompiler'
import { createPrecompiledValidator, ValidatorFunctions } from '@rjsf/validator-ajv8'
import JsonFormToggler from './JsonFormToggler'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { ButtonNew } from '@theloopco/loopos-ds-sdk'
import * as _ from 'lodash'

let resource: any = null
let api: any = null
let csrf: string = ''
let resourceType: string = ''
let allowed_types = ['string', 'number', 'integer', 'object', 'array', 'boolean', 'null']

function formatLabel(str: string) {
  return str.replace(/_/g, ' ').replace(/^\w/, (char) => char.toUpperCase())
}

function convertedSchema(original: any) {
  if (typeof original !== 'object' || original === null) {
    return original
  }

  if (Array.isArray(original)) {
    return original.map(convertedSchema)
  }

  const converted: RJSFSchema = { ...original }

  if (original.enum && original.select_options) {
    converted.enum = original.enum
    converted.enumNames = original.select_options.map((option) => option.label)
    delete converted.select_options
  }

  if (original.type === 'array') {
    converted.items ||= { type: 'string' }
    delete converted.creatable
  } else if (['date', 'date_time', 'time', 'text', 'textarea'].includes(original.type)) {
    converted.type = 'string'
    converted.format = original.type.replace('_', '-')
  } else if (original.type === 'file') {
    converted.type = 'string'
    converted.format = 'data-url'
  }

  if (converted.type && !allowed_types.includes(converted.type.toString())) {
    converted.type = 'string'
  }

  if (original.properties) {
    converted.properties = {}
    for (const key in original.properties) {
      delete original.properties[key].required
      converted.properties[key] = convertedSchema(original.properties[key])
    }
  }

  return converted
}

function createUiSchema(schema: any, initialUiSchema: UiSchema, app: any) {
  // UI Group options
  // { "ui:groups": [
  //  {
  //   "title": string, // Name in accordion
  //   "properties": string[], // Properties that are in this group
  //   "open": boolean, // If starts open or starts closed
  //   "remaining": boolean, // Properties that are not in other groups, go to this
  // }]}

  const props = schema.properties || {}
  const uiSchema: UiSchema = {}

  Object.keys(props).forEach((key) => {
    uiSchema[key] = {
      'ui:options': {
        addButtonProps: { app: app },
        submitButtonOptions: { app: app },
        title: formatLabel(key),
        ...(props[key].type === 'array' &&
          props[key].creatable && {
            addable: true,
            removable: true,
            orderable: false,
          }),
        ...(props[key].type === 'textarea' && {
          widget: 'textarea',
          rows: 5,
        }),
        ...(props[key].format === 'html' && {
          widget: 'html',
        }),
        ...(props[key].type === 'array' &&
          props[key].items &&
          props[key].items.format === 'file-url' && {
            widget: 'file-url',
          }),
      },
    }
  })

  return _.merge(uiSchema, initialUiSchema)
}

function generateGuid() {
  let result = '',
    n = 0
  while (n < 32) {
    result +=
      (~[8, 12, 16, 20].indexOf(n++) ? '-' : '') +
      Math.floor(Math.random() * 16)
        .toString(16)
        .toUpperCase()
  }
  return result
}

function formatData(data: any) {
  let newHash = {}
  Object.keys(data).forEach((key) => {
    if (data[key] !== '') {
      newHash[key] = data[key]
    }
  })
  return newHash
}

const JsonForm: any = ({
  schema,
  defaultUiSchema,
  settings,
  resourceId,
  resourceClass,
  token,
  apiBaseUrl,
  parseSchemaUrl,
  fetchSchemaUrl,
  updateUrl,
  canToggleForm,
  csrfToken,
  app,
  title,
  mainBtnText,
}) => {
  resource = resourceId
  csrf = csrfToken
  api = CoreAPI(token, apiBaseUrl)
  resourceType = resourceClass.split('::')
  resourceType = resourceType[resourceType.length - 1].toLowerCase()

  const generateToken = (type) => {
    return `${Date.now().toString()}_${(Math.random() + 1).toString(36).substring(7)}_${type}`
  }

  const [updatedSchema, setUpdatedSchema] = useState<RJSFSchema>(convertedSchema(schema))
  const [uiSchema, setUiSchema] = useState<UiSchema>(createUiSchema(schema, defaultUiSchema, app))
  const [data, setData] = useState<RJSFSchema>(formatData(settings))
  const [code, setCode] = useState('')
  const [precompiledValidator, setPrecompiledValidator] = useState<ValidatorFunctions>()
  const [validator, setValidator] = useState<ValidatorType<any, RJSFSchema, any>>()
  const [canRender, setCanRender] = useState(false)
  const [renderJsonEditor, setRenderJsonEditor] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [editorErrors, setEditorErrors] = useState<any>([])
  const [editorData, setEditorData] = useState<RJSFSchema>(data)
  const [createToken, setCreateToken] = useState<String>()
  const [updateToken, setUpdateToken] = useState<String>()
  const [destroyToken, setDestroyToken] = useState<String>()

  const onFormChange = async ({ formData }: RJSFSchema, id: any) => {
    if (resourceType === 'script' && formData.script_id !== data.script_id) {
      setCanRender(formData.script_id === undefined)
      const url = fetchSchemaUrl.replace(':resourceId', resourceId).replace(':scriptId', formData.script_id)
      const scriptSchema = await api.fetchSchema(url)
      setUpdatedSchema(convertedSchema(scriptSchema))
      setUiSchema(createUiSchema(updatedSchema, defaultUiSchema, app))
      setData({ ...data, script_id: formData.script_id })
    } else {
      setData({ ...data, ...formData })
    }
  }

  const onEditorChange = ({ jsObject, error }) => {
    if (!error) {
      setEditorData({ ...jsObject })
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }

  const onSubmit = ({ formData }: RJSFSchema, e: FormEvent) => {
    const filteredData = formData
    const url = updateUrl.replace(':id', resource)
    api
      .update(
        {
          id: resource,
          untranslated_settings: filteredData,
          show_popup: true,
        },
        url
      )
      .then((_: any) => {
        const flowNodeUpdateEvent = new Event('flowNodeUpdate')
        document.dispatchEvent(flowNodeUpdateEvent)
        api.markAttachmentsProcessed(
          {
            settings_attachment: {
              resourceable_id: resource,
              resourceable_type: resourceClass,
              destroy_token: destroyToken,
              update_token: updateToken,
              create_token: createToken,
            },
          },
          csrf
        )
      })
      .catch(() => [])
    setData(filteredData)
  }

  const handleToggleChange = () => {
    setRenderJsonEditor(!renderJsonEditor)
    setData({ ...editorData })
  }

  const onEditorSubmit = (e: any) => {
    e.preventDefault()
    const filteredData = editorData
    const errors = validator?.rawValidation(updatedSchema, filteredData).errors
    if (errors?.length) {
      setEditorErrors(errors)
    } else {
      const url = updateUrl.replace(':id', resource)
      api.update(
        {
          id: resource,
          untranslated_settings: filteredData,
          show_popup: true,
        },
        url
      )
      setData(filteredData)
    }
  }

  useEffect(() => {
    api.parseSchema(updatedSchema, parseSchemaUrl, csrf).then((result: any) => {
      setCanRender(code === result.parsed_schema)
      setCode(result.parsed_schema)
    })
  }, [updatedSchema])

  useEffect(() => {
    if (code) {
      const guid = generateGuid()
      const compilationId = resourceType === 'script' ? `${resourceId.toString()}-${guid}` : resourceId.toString()
      evaluateValidator(
        compilationId, // unique id so it recompiles everytime the schema changes for script resource
        code, // result of compileSchemaValidatorsCode returned from the server
        guid // nonce script tag attribute to allow this ib content security policy for the page
      ).then(setPrecompiledValidator)
    }
  }, [code])

  useEffect(() => {
    if (precompiledValidator) {
      setValidator(createPrecompiledValidator(precompiledValidator, updatedSchema))
      setCanRender(true)
    }
  }, [precompiledValidator])

  useEffect(() => {
    setEditorData(data)
    setEditorErrors([])
  }, [data])

  if (!createToken) {
    setCreateToken(generateToken('create'))
  }

  if (!updateToken) {
    setUpdateToken(generateToken('update'))
  }

  if (!destroyToken) {
    setDestroyToken(generateToken('destroy'))
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <p className="text-lg font-medium text-gray-darkest">{title}</p>
        {canToggleForm && (
          <JsonFormToggler handleToggleChange={handleToggleChange} isChecked={renderJsonEditor} app={app} />
        )}
      </div>
      {editorErrors.length > 0 && (
        <div className="bg-red-50 text-red-500 px-3 py-2 text-xs rounded-lg mb-4 mt-3">
          <ul className="px-4" style={{ listStyleType: 'revert' }}>
            {editorErrors.map((error: any) => (
              <li key={error.instancePath}>
                {error.instancePath} {error.message} {error.schema.join(', ')}
              </li>
            ))}
          </ul>
        </div>
      )}

      {renderJsonEditor && (
        <div className="flex flex-col gap-4 my-4">
          <JSONInput
            id="json_form_editor"
            placeholder={data}
            locale={locale}
            height="100%"
            width="100%"
            onChange={onEditorChange}
            waitAfterKeyPress={1000}
          />
          <div className="flex justify-end">
            <ButtonNew
              disabled={!canSubmit}
              text={mainBtnText ?? 'Save'}
              size="large"
              variant="primary"
              app={app}
              handleClick={onEditorSubmit}
            ></ButtonNew>
          </div>
        </div>
      )}
      {canRender && validator && !renderJsonEditor && (
        <Form
          schema={updatedSchema}
          uiSchema={uiSchema}
          formData={data}
          onSubmit={onSubmit}
          omitExtraData={true}
          liveOmit={true}
          validator={validator}
          onChange={onFormChange}
          formContext={{
            csrfToken: csrfToken,
            authToken: token,
            resourceId: resourceId,
            resourceClass: resourceClass,
            destroyToken: destroyToken,
            updateToken: updateToken,
            createToken: createToken,
            app: app,
            mainBtnText: mainBtnText,
          }}
          app={app}
        >
          <div className="flex justify-end w-full">
            <ButtonNew type="submit" text={'Submit'} size="large" variant="primary" app={app}></ButtonNew>
          </div>
        </Form>
      )}
    </>
  )
}

export default JsonForm
