import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'trigger']

  connect() {
  }

  toggle() {
    const type = this.inputTarget.getAttribute('type') === 'password' ? 'text' : 'password'
    this.inputTarget.setAttribute('type', type)

    this.triggerTarget.classList.toggle('fa-eye');
    this.triggerTarget.classList.toggle('fa-eye-slash');

  }
}
