import {
  ChangeEvent,
  useCallback,
  useMemo,
  CSSProperties,
  useState,
  useEffect,
} from "react";
import {
  FormContextType,
  getTemplate,
  Registry,
  RJSFSchema,
  StrictRJSFSchema,
  TranslatableString,
  UIOptionsType,
  WidgetProps,
} from "@rjsf/utils";
import Markdown from "markdown-to-jsx";
import { CoreAPI } from "../../Flows/CoreAPI";
import React from "react";
import { ProtocolImageUploader } from "@theloopco/loopos-ds-sdk";
type FileInfoType = {
  id: number;
  url?: string | null;
  name: string;
  size: number;
  type: string;
};

function addDataToURL(url: string, data: any): string {
  if (url === null) {
    return "";
  }

  return `${url}?id=${data.id}&name=${encodeURIComponent(data.name)}&size=${
    data.size
  }&type=${data.type}`;
}

function createAttachment(
  formContext: any,
  file: File,
  name: string,
  resolve: any
) {
  const formData = new FormData();
  const api = CoreAPI(formContext.authToken);

  formData.append("settings_attachment[file]", file, name);
  formData.append(
    "settings_attachment[resourceable_id]",
    formContext.resourceId
  );
  formData.append(
    "settings_attachment[resourceable_type]",
    formContext.resourceClass
  );
  formData.append("settings_attachment[create_token]", formContext.createToken);

  api
    .settingsAttachments(formData, formContext.csrfToken)
    .then((response: any) => {
      resolve(response);
    });
}

function updateAttachment(
  formContext: any,
  file: File,
  name: string,
  resolve: any
) {
  const formData = new FormData();
  const api = CoreAPI(formContext.authToken);

  formData.append("settings_attachment[new_file]", file, name);
  formData.append("settings_attachment[update_token]", formContext.updateToken);

  api
    .updateSettingsAttachment(
      formContext.attachmentId,
      formData,
      formContext.csrfToken
    )
    .then((response: any) => {
      if (response.id) {
        resolve(response);
      } else {
        createAttachment(formContext, file, name, resolve);
      }
    });
}

function uploadAttachment(formContext: any, file: File, name: string) {
  return new Promise((resolve, reject) => {
    if (formContext.attachmentId) {
      updateAttachment(formContext, file, name, resolve);
    } else {
      createAttachment(formContext, file, name, resolve);
    }
  });
}

function updateDestroyToken(formContext: any, url: string) {
  return new Promise((resolve, _) => {
    const api = CoreAPI(formContext.authToken);
    const data = extractFileInfo([url])[0];
    const formData = new FormData();

    formData.append(
      "settings_attachment[destroy_token]",
      formContext.destroyToken
    );
    api
      .updateSettingsAttachment(data.id, formData, formContext.csrfToken)
      .then((response: any) => {
        resolve(response);
      });
  });
}

function processFile(formContext: any, file: File): Promise<FileInfoType> {
  const { name, size, type } = file;
  return new Promise((resolve, _) => {
    uploadAttachment(formContext, file, name).then((result: any) => {
      resolve({
        id: result.id,
        url: addDataToURL(result.file.url, { id: result.id, name, size, type }),
        name,
        size,
        type,
      });
    });
  });
}

function processFiles(files: FileList, formContext: any) {
  return Promise.all(
    Array.from(files).map((file) => processFile(formContext, file))
  );
}

function extractFileInfo(urls: string[]): FileInfoType[] {
  if (!urls) {
    return [] as FileInfoType[];
  }
  return urls.reduce((arr: any, url: string) => {
    if (!url) {
      return arr;
    }
    try {
      const queryString = new URL(url).search;
      const urlParams = new URLSearchParams(queryString);

      return [
        ...arr,
        {
          id: Number(urlParams.get("id")),
          url: url,
          name: urlParams.get("name") || "",
          size: Number(urlParams.get("size")),
          type: urlParams.get("type") || "",
        },
      ];
    } catch (e) {
      console.log(e);

      return arr;
    }
  }, [] as FileInfoType[]);
}

function FileUrlWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: WidgetProps<T, S, F>) {
  const {
    disabled,
    readonly,
    required,
    multiple,
    onChange,
    value,
    options,
    registry,
    schema,
  } = props;

  const handleNewFiles = (files: FileList, prevFiles: FileList) => {
    processFiles(files, props.formContext).then((filesInfoParams) => {
      const newValue = filesInfoParams.map((fileInfo) => fileInfo.url);
      if (multiple) {
        let urls = Array.from(prevFiles)
          .filter((item) => typeof item === "object" && "url" in item)
          .map((item) => item?.url);
        onChange(urls.concat(newValue));
      } else {
        onChange(newValue[0]);
      }
    });
  };

  const handleOldAttachment = (old: string, newFile: File) => {
    const data = extractFileInfo([old])[0];
    const formContext = props.formContext as Object;
    formContext["attachmentId"] = data.id;
    processFile(formContext, newFile).then((fileInfo) => {
      if (multiple) {
        let urls = Array.isArray(value) ? value : [value];
        let newValues = urls.filter((e) => e !== old);
        onChange(newValues.concat(fileInfo.url));
      } else {
        onChange(fileInfo.url);
      }
    });
  };

  const rmFile = (url: string) => {
    updateDestroyToken(props.formContext, url).then((_: any) => {
      if (multiple) {
        const urls = Array.isArray(value) ? value : [value];
        const newValue = urls.filter((u: any, i: number) => u !== url);
        onChange(newValue);
      } else {
        onChange(undefined);
      }
    });
  };

  const handleChange = useCallback(
    (imageList) => {
      const files = imageList.map((image) => image.file);
      if (files && value && files.length > 0 && value.length === files.length) {
        let fileUrls = files.map((f) => f.url);
        let removedFile = value.filter((url) => !fileUrls.includes(url));
        const fileElement = files.filter((file) => file instanceof File);
        if (removedFile?.length > 0 && fileElement?.length > 0)
          handleOldAttachment(removedFile[0], fileElement[0]);
      } else {
        if (
          files &&
          value &&
          Array.isArray(value) &&
          value.length > files.length
        ) {
          let fileUrls = files.map((f) => f.url);
          let removedFile = value.filter((url) => !fileUrls.includes(url));
          rmFile(removedFile?.[0]);
        } else {
          const fileElements = files.filter((file) => file instanceof File);
          handleNewFiles(fileElements, files);
        }
      }
    },
    [multiple, value, onChange]
  );

  const filesInfo = useMemo(
    () => extractFileInfo(Array.isArray(value) ? value : [value]),
    [value]
  );

  return (
    <div>
      <ProtocolImageUploader
        images={filesInfo.map((fileInfo) => ({
          dataURL: fileInfo.url,
          file: fileInfo,
        }))}
        updateImages={handleChange}
        //FIX-ME: Uploader does not support infinite max items
        maxImages={multiple ? (schema?.maxItems ? schema?.maxItems : 10) : 1}
        editable={!(disabled || readonly)}
      />
    </div>
  );
}

export default FileUrlWidget;
