import React, { useState } from 'react'
import { Accordion } from '@theloopco/loopos-ds-sdk'

export function GroupTemplate(props) {
  const { name, children, initialOpen } = props
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen || false)

  const hidden = !name

  return (
    <>
      {!hidden && (
        <Accordion title={name} variant="settings" isOpen={isOpen} handleClick={() => setIsOpen(!isOpen)}>
          {children}
        </Accordion>
      )}
      {hidden && children}
    </>
  )
}
