import * as React from "react";
import { AiOutlineArrowDown } from "@react-icons/all-files/ai/AiOutlineArrowDown";
import { AiOutlineArrowUp } from "@react-icons/all-files/ai/AiOutlineArrowUp";
import { IoIosCopy } from "@react-icons/all-files/io/IoIosCopy";
import {
  FormContextType,
  IconButtonProps as OriginalIconButtonProps,
  RJSFSchema,
  StrictRJSFSchema,
  TranslatableString,
} from "@rjsf/utils";
import { ButtonNew } from "@theloopco/loopos-ds-sdk";

interface CustomIconButtonProps<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
> extends OriginalIconButtonProps<T, S, F> {
  app?: any;
}

export default function IconButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: CustomIconButtonProps<T, S, F>) {
  const {
    app,
    onClick,
    icon,
    iconType,
    className,
    uiSchema,
    registry,
    disabled,
    ...otherProps
  } = props;

  return (
    <div className="flex justify-end items-end">
      <ButtonNew
        // @ts-expect-error incomplete props from rjsf
        app={props?.variant ?? app}
        variant="secondary"
        iconPosition="left"
        icon={icon}
        text={""}
        size="large"
        railsIcon
        handleClick={onClick as unknown as () => void}
      ></ButtonNew>
    </div>
  );
}

export function CopyButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: CustomIconButtonProps<T, S, F>) {
  const {
    registry: { translateString },
  } = props;
  return (
    <IconButton
      title={translateString(TranslatableString.CopyButton)}
      {...props}
      icon={<IoIosCopy />}
    />
  );
}

export function MoveDownButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: CustomIconButtonProps<T, S, F>) {
  const {
    registry: { translateString },
  } = props;
  return (
    <IconButton
      title={translateString(TranslatableString.MoveDownButton)}
      {...props}
      icon={<AiOutlineArrowDown />}
    />
  );
}

export function MoveUpButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: CustomIconButtonProps<T, S, F>) {
  const {
    registry: { translateString },
  } = props;
  return (
    <IconButton
      title={translateString(TranslatableString.MoveUpButton)}
      {...props}
      icon={<AiOutlineArrowUp />}
    />
  );
}

export function RemoveButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: CustomIconButtonProps<T, S, F>) {
  const {
    registry: { translateString },
    onClick,
    app,
  } = props;
  return (
    <IconButton
      title={translateString(TranslatableString.RemoveButton)}
      // @ts-expect-error incomplete props from rjsf
      variant="danger"
      icon={"fa-regular fa-xmark"}
      onClick={onClick}
      app={app}
      {...props}
    />
  );
}
