import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy(){
    if (!this.hasSourceTarget) return;

    const text = this.sourceTarget.innerHTML;
    navigator.clipboard.writeText(text);
  }
}