import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['scopableInput', 'instanceInput', 'scopeInput'];

  connect() {
    if (this.hasScopableInputTarget) {
      const scopableKlass = this.scopableInputTargets[0].value;
      if (scopableKlass) this.onScopableChange()
    }
  }

  async onScopableChange() {
    if (!this.hasScopableInputTarget) {
      return;
    }

    const scopableKlass = this.scopableInputTargets[0].value;
    const index = scopableKlass === 'User' ? 1 : 2;
    const scopableId = this.scopableInputTargets[index].value;
    const instanceValues = await this.fetchInstanceValues(scopableId, scopableKlass);
    this.updateInstanceValues(instanceValues);
  }

  async onInstanceChange() {
    if (!this.hasInstanceInputTarget) {
      return;
    }

    const instanceId = this.instanceInputTarget.value;
    const scopeValues = await this.fetchScopeValues(instanceId);
    this.updateScopeValues(scopeValues);
  }

  async fetchInstanceValues(scopableId, scopableKlass) {
    if (!scopableId || !scopableKlass) {
      return [];
    }

    const response = await fetch(
      `${window.Avo.configuration.root_path}/resources/app_management_app_instance_scopes/instance_values?scopable_id=${scopableId}&scopable_klass=${scopableKlass}`,
    );
    return await response.json();
  }

  async fetchScopeValues(instanceId) {
    const response = await fetch(
      `${window.Avo.configuration.root_path}/resources/app_management_app_instance_scopes/scope_values?instance_id=${instanceId}`,
    );
    return await response.json();
  }

  updateInstanceValues(instanceValues) {
    while (this.instanceInputTarget.options.length > 0) {
      this.instanceInputTarget.remove(0);
    }

    this.instanceInputTarget.add(new Option('Select an app instance', ''));

    instanceValues.forEach(([id, name]) => {
      this.instanceInputTarget.add(new Option(name, id));
    });
  }

  updateScopeValues(scopeValues) {
    while (this.scopeInputTarget.options.length > 0) {
      this.scopeInputTarget.remove(0);
    }

    this.scopeInputTarget.add(new Option('Select scope', ''));

    scopeValues.forEach(([id, name]) => {
      this.scopeInputTarget.add(new Option(name, id));
    });
  }
}
