import instantsearch from 'instantsearch.js';
import {
  searchBox,
  stats,
  panel,
  index,
  configure,
} from 'instantsearch.js/es/widgets';
import {
  connectRefinementList,
  connectCurrentRefinements,
  connectInfiniteHits,
  connectRange,
} from 'instantsearch.js/es/connectors';
import { I18n } from 'i18n-js';
import translations from './translations.json';

let triggerRefine = [];

document.addEventListener('turbo:load', setupAlgolia);
document.addEventListener('DOMContentLoaded', setupAlgolia);

// stored selected refinement filters
let storedFilters = [];
let appliedFilters = [];

// searchbox debounce > currently inactive
let timerId;
let timeout = 0;

function setupAlgolia() {
  let algoliaConfig = {};

  try {
    algoliaConfig =
      document.getElementById('algolia-config').dataset.algoliaConfig;
  } catch (error) {
    return;
  }

  let algolia_config_obj = JSON.parse(algoliaConfig);
  let hitsTables = algolia_config_obj.hits_tables;

  const algoliasearch = require('algoliasearch');
  const searchClient = algoliasearch(
    algolia_config_obj.credentials.application_id,
    algolia_config_obj.credentials.api_key,
  );

  const search = instantsearch({
    searchClient,
  });

  const i18n = new I18n(translations);
  i18n.locale = algolia_config_obj.dashboard_language;

  function render_hit(hit, partial, current_container_id) {
    const Handlebars = require('handlebars');

    Handlebars.registerHelper('isEquals', (arg1, arg2) => {
      const stripHTML = (str) => {
        if (typeof str !== 'string') return str;
        return str.replace(/<\/?[^>]+(>|$)/g, '');
      };

      const strippedArg1 = stripHTML(arg1 ? arg1.toString() : '');
      const strippedArg2 = stripHTML(arg2 ? arg2.toString() : '');

      return strippedArg1 == strippedArg2;
    });

    const algoliaConfig =
      document.getElementById('algolia-config').dataset.algoliaConfig;
    let algolia_config_obj = JSON.parse(algoliaConfig);
    let models_attributes = algolia_config_obj.models_attributes;
    let attributes = '';

    models_attributes.forEach((model_attributes) => {
      if (model_attributes.container_id === current_container_id) {
        attributes = model_attributes.attributes;
      }
    });

    var template = Handlebars.compile(partial);

    // This is an attempt to fix the HTML sanitization for react props
    // Add here the names of algolia attributes that will be used as props
    // to react components. Simple attributes (eg: strings, numbers) should be fine (as Tags, for example)
    // but more complex props (eg: objects, for TagLists) need to be here
    // God will forgive, but I won't
    const attributes_for_react_props = [
      'tags_user_groups_name', // Manager
      'tags_categories_name', // Core
      'tags_children_categories_name', // Core
      'tags_brands_name', // Core
    ];

    if (current_container_id === 'hits-products' && hit['tags_brands_name'] !== null && Array.isArray(hit['tags_brands_name'])) {
      hit['tags_brands_name'] =
        'REMOVE_THIS' +
        JSON.stringify(Array.from(hit['tags_brands_name'])).replace(
          /"/g,
          '&quot;',
        ) +
        'REMOVE_THIS';
    }

    if (
      current_container_id === 'hits-products' &&
      hit['tags_brands_name'] !== null &&
      Array.isArray(hit['tags_brands_name'])
    ) {
      hit['tags_brands_name'] =
        'REMOVE_THIS' +
        JSON.stringify(Array.from(hit['tags_brands_name'])).replace(
          /"/g,
          '&quot;',
        ) +
        'REMOVE_THIS';
    }

    if (hit['tags_user_groups_name'] && hit['tags_user_groups_name'] === '[]') {
      hit['tags_user_groups_name'] = null;
    }

    if (current_container_id === 'hits-products' && hit['tags_brands_name'] !== null && Array.isArray(hit['tags_brands_name'])) {
      hit['tags_brands_name'] =
        'REMOVE_THIS' +
        JSON.stringify(Array.from(hit['tags_brands_name'])).replace(
          /"/g,
          '&quot;',
        ) +
        'REMOVE_THIS';
    }

    for (const attr of attributes_for_react_props) {
      if (hit.hasOwnProperty(attr) && hit[attr] && hit[attr] !== null) {
        if (!hit[attr].startsWith('REMOVE_THIS')) {
          hit[attr] =
            'REMOVE_THIS' + hit[attr].replace(/"/g, '&quot;') + 'REMOVE_THIS';
        }
      }
    }

    // This will remove the <mark> tags from the highlighted attributes (eg: Tags, TagLists, etc)
    const ignore_markings_attributes = {
      // Manager
      'hits-users': ['tags_user_groups_name', 'partnable_name'],
      'hits-loop_os_services_email_messages': ['status', 'email_status_kind'],
      'hits-loop_os_services_payments': ['status', 'payment_kind'],
      // Core
      'hits-products': [
        'icon',
        'title',
        'variant',
        'kind',
        'tSize',
        'tags_categories_name',
      ],
      'hits-items': [
        'icon',
        'macro_text',
        'micro_text',
        'tags_categories_name',
      ],
      // Hubs
      'hits-loopos_orders': ['status'],
      'hits-loopos_shippings': ['status'],
      // General
      global: ['id'],
    };

    let ignore_list = [
      ...(ignore_markings_attributes[current_container_id] || []),
      ...ignore_markings_attributes['global'],
    ];

    // console.log(current_container_id);
    // console.log(ignore_list);

    for (const ignore_markings_attribute of ignore_list) {
      if (hit.hasOwnProperty(ignore_markings_attribute)) {
        hit[ignore_markings_attribute] = hit[
          ignore_markings_attribute
        ]?.replace(/<mark>/g, '');
        hit[ignore_markings_attribute] = hit[
          ignore_markings_attribute
        ]?.replace(/<\/mark>/g, '');
      }
    }

    var html = template(hit);
    html = html.replace(/&quot;REMOVE_THIS/g, '');
    html = html.replace(/REMOVE_THIS&quot;/g, '');
    html = html.replace(/&amp;/g, '&');

    return html;
  }

  function get_partial(current_container_id) {
    const algoliaConfig =
      document.getElementById('algolia-config').dataset.algoliaConfig;
    let algolia_config_obj = JSON.parse(algoliaConfig);
    let partials = algolia_config_obj.table_line_partials;
    let partial = '';

    partials.forEach((current_partial) => {
      if (current_partial.container_id === current_container_id) {
        partial = current_partial.partial;
      }
    });

    return partial;
  }

  const renderListItem = (item) => `
    <div class="loopui-usearch__current-refinements--item" data-index_name="${
      item.indexName
    }" data-attribute="${item.attribute}">
      ${
        item.indexName
          ? item.indexName.charAt(0).toUpperCase() + item.indexName.slice(1)
          : item.label.charAt(0).toUpperCase() + item.label.slice(1)
      }
      ${item.refinements
        .map(
          (refinement, index) =>
            `<span>
              "${refinement.label}"
              ${
                index < item.refinements.length - 1
                  ? ','
                  : `<button data-indexName="${item.indexName}" data-attribute="${item.attribute}">×</button>`
              }
            </span>`,
        )
        .join('')}
    </div>
  `;

  const renderCurrentRefinements = (renderOptions, isFirstRender) => {
    const { items, refine, widgetParams } = renderOptions;

    const container = document.getElementById(
      widgetParams.container.replace('#', ''),
    );

    items.length
      ? container.classList.remove('hidden')
      : container.classList.add('hidden');

    container.innerHTML = `
      <div class="loopui-usearch__current-refinements">
        ${items.map(renderListItem).join('')}
      </div>
    `;

    // Update general stats
    updateGeneralStats();

    [...container.querySelectorAll('button')].forEach((element) => {
      element.addEventListener('click', (event) => {
        items.forEach((item) => {
          if (
            item.indexName === event.currentTarget.dataset['indexname'] &&
            item.attribute === event.currentTarget.dataset.attribute
          ) {
            item.refinements.forEach(async (refinement) => {
              const appliedFilter = appliedFilters.find(
                (el) =>
                  el.index_name === item.indexName &&
                  el.value === refinement.value,
              );

              if (appliedFilter) {
                const appliedIndex = appliedFilters.indexOf(appliedFilter);
                const storedIndex = storedFilters.indexOf(appliedFilter);
                appliedFilters.splice(appliedIndex, 1);
                storedFilters.splice(storedIndex, 1);
              }
              await refine(refinement);
            });
          }
        });
      });
    });
  };

  const updateGeneralStats = () => {
    let counter = 0;
    document
      .querySelectorAll(
        '.loopui-usearch__tabs .loopui-usearch__results__table--title__stats',
      )
      .forEach((stat) => {
        counter += parseInt(stat.innerText.replace('(', '').replace(')', ''));
      });
    document.getElementById('general-stats').textContent = `(${counter})`;
  };

  // Create the custom widget
  const customCurrentRefinements = connectCurrentRefinements(
    renderCurrentRefinements,
  );

  const checkNoResults = (container_id, hits, isFirstRender) => {
    if (isFirstRender) {
      // Get total refinements
      const hasRefinements =
        Array.from(document.querySelectorAll('.ais-Panel-body__refinement'))
          .length > 0;

      // If total refinements === 0, hide global search fill search fields message
      if (!hasRefinements) {
        document.getElementById('global-search-no-search').style.display =
          'none';
      }
      return;
    }

    const container = document.querySelector(`#${container_id}_container`);
    const totalStats = parseInt(
      document
        .getElementById('general-stats')
        .textContent.replace('(', '')
        .replace(')', ''),
    );
    const hasFilters =
      parseInt(
        document
          .getElementById('filters-counter')
          .textContent.replace('(', '')
          .replace(')', ''),
      ) > 0
        ? true
        : false;
    const emptyResultsMessage = document.getElementById(
      'empty-results-all-tables',
    );

    /*
     * TOGGLE INITIAL FLOW MESSAGE (APPLY FILTERS)
     */
    if (hasFilters) {
      // Hide no search message
      document
        .querySelector('.loopui-usearch__tabs-results--container')
        .classList.add('show-results');
      document.getElementById('global-search-no-search').style.display = 'none';

      /*
       * CHECK NUMBER OF RESULTS
       */
      if (hits.length === 0) {
        container.classList.add('hidden');

        if (container.classList.contains('selected')) {
          emptyResultsMessage.classList.remove('hidden');
        }
      } else {
        if (container.classList.contains('selected')) {
          container.classList.remove('hidden');
          emptyResultsMessage.classList.add('hidden');
          document
            .querySelector('.loopui-usearch__results')
            .classList.remove('hidden');
        }
      }

      const isTabAllSelected =
        Array.from(
          document.querySelector('.loopui-usearch__results').children,
        ).find((table) => table.classList.contains('selected')) === undefined;

      if (isTabAllSelected) {
        if (hits.length > 0) {
          container.classList.remove('hidden');
          document
            .querySelector('.loopui-usearch__results')
            .classList.remove('hidden');
        }

        const notAllHidden = Array.from(
          document.querySelector('.loopui-usearch__results').children,
        ).find((el) => !el.classList.contains('hidden'));

        if (notAllHidden) {
          document
            .querySelector('.loopui-usearch__results')
            .classList.remove('hidden');
          emptyResultsMessage.classList.add('hidden');
        } else {
          document
            .querySelector('.loopui-usearch__results')
            .classList.add('hidden');
          emptyResultsMessage.classList.remove('hidden');
        }
      }
    } else {
      // Show no search message
      document
        .querySelector('.loopui-usearch__tabs-results--container')
        .classList.remove('show-results');

      // Get total refinements
      const hasRefinements =
        Array.from(document.querySelectorAll('.ais-Panel-body__refinement'))
          .length > 0;
      if (hasRefinements) {
        document.getElementById('global-search-no-search').style.display =
          'flex';
      }

      emptyResultsMessage.classList.add('hidden');
    }
  };

  const renderInfiniteHits = (renderOptions, isFirstRender) => {
    const { hits, widgetParams, showMore, isLastPage } = renderOptions;

    setTimeout(() => {
      checkNoResults(widgetParams.container.id, hits, isFirstRender);
    }, 100);

    widgetParams.container.innerHTML = `
    ${
      hits.length
        ? hits
            .map((hit) =>
              render_hit(
                hit,
                get_partial(widgetParams.container.id),
                widgetParams.container.id,
              ),
            )
            .join('')
        : '<span class="loopui-usearch__results__table--no-results">No results</span>'
    }
  `;

    const tabAll = document.querySelector(`#${widgetParams.container.id}_container table`).classList.contains('tab-all')

    if (!isLastPage) {
      widgetParams.container.innerHTML += `
        <div class="loopui-usearch__results__table--show-more ${tabAll ? 'hidden' : ''}">
          <button>${i18n.t('show_more')}</button>
        </div>
      `;

      widgetParams.container
        .querySelector('button')
        .addEventListener('click', () => {
          showMore();
        });
    }
  };

  // Create the custom widget
  const customInfiniteHits = connectInfiniteHits(renderInfiniteHits);

  search.addWidgets([
    searchBox({
      container: '#searchbox',
      placeholder: i18n.t('search'),
      autofocus: false,
      cssClasses: {
        form: 'loopui-usearch__search-box--form',
        input: 'loopui-usearch__search-box--input',
        submit: 'loopui-usearch__search-box--submit',
        submitIcon: 'loopui-usearch__search-box--submit-icon',
        reset: 'loopui-usearch__search-box--reset',
        resetIcon: 'loopui-usearch__search-box--reset-icon',
      },
      queryHook(query, refine) {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          const searchBoxFilter = document.getElementById('searchbox-filter');

          refine(query);
          sendRequest(query);

          if (query.length) {
            searchBoxFilter.classList.remove('hidden');
            searchBoxFilter.innerHTML = `
            <div class="loopui-usearch__current-refinements--item">
            <span data-attribute="search-filter">"${query}"</span>
              <button id="current-refinement-searchbox-button">x</button>
            </div>
          `;

            // Add listener to close search
            const searboxRefinementButton = document.getElementById(
              'current-refinement-searchbox-button',
            );
            searboxRefinementButton.addEventListener('click', () => {
              closeSearchBox();
              searboxRefinementButton.removeEventListener('click', this);
            });
          } else {
            searchBoxFilter.classList.add('hidden');
            searchBoxFilter.innerHTML = '';
          }
        }, timeout);
      },
    }),
    // Define your configuration object
    (() => {
      let config = {
        hitsPerPage: algolia_config_obj.hits_per_page,
        enablePersonalization: true,
      };

      let current_hub =
        document.getElementById('algolia-config').dataset.currentHub;

      // Check the condition where you want to include the filters line
      if (current_hub !== '') {
        config.filters = `hub_name: "${current_hub}"`;
      }

      return configure(config);
    })(),
  ]);

  // This code will run after all widgets have been rendered
  search.on('render', () => {
    updateFilters();

    setTimeout(() => {
      let noResults = true;
      document.querySelectorAll('table tbody').forEach(el => {
          if (el.querySelectorAll('tr').length) {
            noResults = false;
          }
        }
      )

      noResults ?
        document.querySelector('#empty-results-all-tables').classList.remove('hidden') :
        document.querySelector('#empty-results-all-tables').classList.add('hidden');
    }, 100);

    // Ensure that badge visibility is udpdated
    setTimeout(() => {
      updateFilters();
    }, 1000)
  });

  const checkEmptyRefinements = (container_id) => {
    const container = document.getElementById(container_id);
    const isEmpty = !container.querySelectorAll('.ais-Panel-body__refinement')
      .length;
    if (isEmpty) {
      container.style.display = 'none';

      const selectFilter = container.querySelector(
        `.ais-Panel-header__select-filter`,
      );
      const backButton = container.querySelector(`.ais-Panel-header__back`);

      // Check if header__back is visible
      if (window.getComputedStyle(selectFilter).display === 'none') {
        backButton.click();
      }
    } else {
      // Check if other containers have selectFilter visible
      const isSelected = Array.from(
        document.querySelectorAll(
          `#${container.dataset.attribute} .ais-Panel-header__back`,
        ),
      ).find((el) => window.getComputedStyle(el).display === 'flex');

      if (isSelected === undefined) container.style.display = 'block';
    }

    // Check if all container are empty
    const filters = Array.from(
      document.querySelectorAll(
        '.loopui-usearch__advanced-search__filters--filter',
      ),
    );
    filters.forEach((el) => {
      let isEmpty = !el.querySelectorAll('.ais-Panel-body__refinement').length;
      el.querySelector('.loopui-usearch__no-results-message').style.display =
        isEmpty ? 'flex' : 'none';
    });
  };

  const renderRefinementList = async (renderOptions, isFirstRender) => {
    const { items, refine, widgetParams } = renderOptions;

    if (isFirstRender) {
      const selectFilter = document.querySelector(
        `#${widgetParams.container_id} .ais-Panel-header__select-filter`,
      );
      const backButton = document.querySelector(
        `#${widgetParams.container_id} .ais-Panel-header__back`,
      );
      const body = document.querySelector(
        `#${widgetParams.container_id} .ais-Panel-body`,
      );
      const applyButton = document.querySelector(
        `#${widgetParams.attribute}_apply_button`,
      );

      selectFilter.addEventListener('click', () => {
        selectFilter.style.display = 'none';
        backButton.style.display = 'flex';
        body.style.display = 'block';
        applyButton.style.display = 'block';

        // hide other ais-Panel's
        Array.from(
          document.querySelectorAll(
            `#${widgetParams.attribute}_input .loopui-usearch__advanced-search__filters--list__item`,
          ),
        ).forEach((el) => {
          if (el.id !== widgetParams.container_id) {
            el.style.display = 'none';
          }
        });
      });

      backButton.addEventListener('click', () => {
        selectFilter.style.display = 'flex';
        backButton.style.display = 'none';
        body.style.display = 'none';
        applyButton.style.display = 'none';

        // show all ais-Panel's
        Array.from(
          document.querySelectorAll(
            `#${widgetParams.attribute}_input .loopui-usearch__advanced-search__filters--list__item`,
          ),
        ).forEach((el) => {
          if (el.querySelectorAll('.ais-Panel-body__refinement').length) {
            el.style.display = 'block';
          }
        });
      });
    }

    widgetParams.container.innerHTML = `
      ${items
        .map(
          (item) => `
                <div
                  data-value="${item.value}"
                  class="ais-Panel-body__refinement"
                >
                  <input id="${widgetParams.container_id}-${
            item.value
          }" data-value="${item.value}" data-index_name="${
            widgetParams.index_name
          }" type="checkbox" ${
            item.isRefined ? 'checked' : ''
          } class="ais-Panel-body__refinement--checkbox" />
                  <label for="${widgetParams.container_id}-${
            item.value
          }" class="ais-Panel-body__refinement--label ${
            item.isRefined ? 'ais-Panel-body__refinement--label--selected' : ''
          }">${item.label}</label>
                </div>
              `,
        )
        .join('')}
    `;

    setTimeout(() => {
      checkEmptyRefinements(widgetParams.container_id);
    }, 100);

    [
      ...widgetParams.container.querySelectorAll('.ais-Panel-body__refinement'),
    ].forEach((element) => {
      element.addEventListener('click', (event) => {
        let input = event.target;
        if (
          event.target.classList.contains('ais-Panel-body__refinement') ||
          (event.currentTarget.classList.contains(
            'ais-Panel-body__refinement',
          ) &&
            event.target.tagName.toLowerCase() !== 'input')
        ) {
          input = event.target.querySelector('input');
          input.checked = !input.checked;
        }
        // event.preventDefault();

        // const input = element.querySelector('input');
        // input.checked = !input.checked;

        // Check if this filter is in the array
        const filter = storedFilters.find(
          (el) =>
            el.container_id === widgetParams.container_id &&
            el.value === input.dataset.value,
        );

        if (!filter) {
          storedFilters.push({
            container_id: widgetParams.container_id,
            attribute: widgetParams.attribute,
            value: input.dataset.value,
            index_name: input.dataset.index_name,
            refine: refine,
          });
        } else {
          const index = storedFilters.indexOf(filter);
          storedFilters.splice(index, 1);
        }

        const applyButton = document.querySelector(
          `#${widgetParams.attribute}_input button`,
        );

        // Check if checked inputs exist
        const hasCheckedInputs = [
          ...document.querySelectorAll(
            `#${widgetParams.attribute}_input input`,
          ),
        ].filter((el) => el.checked);

        //  Check if current refinements
        //    - If they exist, check if it is from searchbox or date
        const hasSearchBoxFilter =
          document.getElementById('searchbox-filter').innerHTML !== '';
        const hasDateFilter =
          document.getElementById('date-range-filter').innerHTML !== '';
        const currentRefinements = document.querySelectorAll(
          '.loopui-usearch__current-refinements--item',
        ).length;

        if (currentRefinements === 0) {
          hasCheckedInputs.length
            ? applyButton.classList.remove('disabled')
            : applyButton.classList.add('disabled');
          applyButton.disabled = !hasCheckedInputs.length;
        } else {
          if (
            (currentRefinements === 2 && hasSearchBoxFilter && hasDateFilter) ||
            (currentRefinements === 1 &&
              (hasSearchBoxFilter || hasDateFilter) &&
              !hasCheckedInputs.length)
          ) {
            applyButton.classList.add('disabled');
            applyButton.disabled = true;
          } else {
            applyButton.classList.remove('disabled');
            applyButton.disabled = false;
          }
        }
      });
    });
  };

  const customRefinementList = connectRefinementList(renderRefinementList);

  // Create the render function
  const renderRangeInput = (renderOptions, isFirstRender) => {
    const { refine, widgetParams } = renderOptions;

    if (isFirstRender) {
      window.addEventListener('DateRangeEvent', () => {
        if (window.localStorage.getItem('date') === null) return;

        if (
          JSON.parse(window.localStorage.getItem('date')).startDate.length &&
          JSON.parse(window.localStorage.getItem('date')).endDate.length
        ) {
          // Format start date
          const startDateParts = JSON.parse(
            window.localStorage.getItem('date'),
          ).startDate.split('/');
          const formattedStartDate = new Date(
            startDateParts[2],
            startDateParts[1] - 1,
            startDateParts[0],
          );

          // Format end date
          const endDateParts = JSON.parse(
            window.localStorage.getItem('date'),
          ).endDate.split('/');
          const formattedEndDate = new Date(
            endDateParts[2],
            endDateParts[1] - 1,
            endDateParts[0],
          );

          refine([
            Math.floor(formattedStartDate.getTime() / 1000),
            Math.floor(formattedEndDate.getTime() / 1000),
          ]);

          // Fill current filter
          const rangeFilter = document.getElementById('date-range-filter');
          rangeFilter.classList.remove('hidden');

          rangeFilter.innerHTML = `
            <div class="loopui-usearch__current-refinements--item">
              ${i18n.t('date')}
              <span data-attribute="date-range-filter">
                "${i18n.t('from')}
                ${JSON.parse(window.localStorage.getItem('date')).startDate}
                ${i18n.t('to')}
                ${JSON.parse(window.localStorage.getItem('date')).endDate}"
              </span>
              <button id="current-refinement-date-button">x</button>
            </div>
          `;

          document
            .getElementById('current-refinement-date-button')
            .addEventListener('click', () => {
              document.querySelector('.datepicker--container__clear').click();
            });
        } else {
          const rangeFilter = document.getElementById('date-range-filter');
          rangeFilter.classList.add('hidden');
          rangeFilter.innerHTML = '';
          refine([undefined, undefined]);
        }
      });
    }
  };

  const customRangeInput = connectRange(renderRangeInput);

  hitsTables.forEach((indexConfig) => {
    const {
      index_name,
      container_id,
      container_stats_id,
      filters,
      tabs_stats_id,
      current_refinements_id,
    } = indexConfig;

    const filterWidgets = filters.select_filters.map((filter) => {
      return panel({
        templates: {
          header(options, { html }) {
            return html`
              <div class="ais-Panel-header__select-filter">
                <span>${filter.model_name}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                  style="fill: rgb(73, 80, 87); width: 14px;"
                >
                  <path
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </div>
              <div class="ais-Panel-header__back">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style="width: 10px; fill: rgb(73, 80, 87);"
                >
                  <path
                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                  ></path>
                </svg>
                <span>${i18n.t('back')}</span>
              </div>
            `;
          },
          noResults() {
            return 'No results.';
          },
        },
      })(customRefinementList)({
        container: filter.container_id,
        attribute: filter.attribute,
        searchable: false,
        container_id: filter.container_id.replace('#', ''),
        index_name: index_name,
      });
    });

    const dateWidgets = filters.date_range_filters.map((filter) => {
      return panel({
        templates: {
          header: filter.model_name,
          noResults() {
            return 'No results.';
          },
        },
      })(customRangeInput)({
        container: filter.container_id,
        attribute: filter.attribute,
        searchable: false,
        container_id: filter.container_id.replace('#', '')
      });
    });

    search.addWidgets([
      index({
        indexName: index_name,
      }).addWidgets([
        customInfiniteHits({
          container: document.querySelector(container_id),
          transformItems(items) {
            return items.map((item) => {
              let properties = Object.keys(item)
                .map((k) => k)
                .filter((k) => k !== '_highlightResult');

              let res = {};
              for (const key of properties) {
                res[key] = item[key];
                if (
                  typeof res[key] !== 'object' &&
                  typeof res[key] !== 'boolean'
                ) {
                  res[key] = item._highlightResult?.[key]?.value;
                }
              }

              return {
                ...item,
                ...res,
              };
            });
          },
          showPrevious: true,
        }),
        stats({
          container: container_stats_id,
          templates: {
            text(data, { html }) {
              return html`<span
                class="loopui-usearch__results__table--title__stats"
                >(${data.nbHits})</span
              >`;
            },
          },
        }),
        stats({
          container: tabs_stats_id,
          templates: {
            text(data, { html }) {
              return html`<span
                class="loopui-usearch__results__table--title__stats"
                >(${data.nbHits})</span
              >`;
            },
          },
        }),
        customCurrentRefinements({
          container: current_refinements_id,
          transformItems(items) {
            return items.filter((item) => item.indexName === index_name);
          },
          index_name: index_name,
          excludedAttributes: ['created_at_to_i', 'updated_at_to_i'],
        }),
        ...filterWidgets,
        ...dateWidgets,
      ]),
    ]);
  });

  search.start();
  window.algoliaSearch = search;
}

const closeFilterLists = () => {
  [
    ...document.querySelectorAll(
      '.loopui-usearch__advanced-search__filters--apply-button',
    ),
  ].forEach((button) => {
    button.classList.add('disabled');
    button.disabled = true;
  });

  // Close all opened filters dropdown
  [
    ...document.querySelectorAll(
      '.loopui-usearch__advanced-search__filters--list',
    ),
  ].forEach((list) => {
    list.classList.remove('hidden');
  });
};

const isRefined = (index_name, value) => {
  const currentRefinements = document.querySelectorAll(
    '.loopui-usearch__current-refinements--item',
  );
  if (currentRefinements.length) {
    const isRefined = Array.from(currentRefinements).find(
      (el) =>
        el.textContent.includes(value) && el.dataset.index_name === index_name,
    );
    return isRefined !== undefined;
  }
  return false;
};

const checkNoRefinements = () => {
  const filters = document.querySelectorAll(
    '.loopui-usearch__advanced-search__filters--filter',
  );

  Array.from(filters).forEach((filter) => {
    const filter_item = filter.querySelectorAll(
      '.loopui-usearch__advanced-search__filters--list__item',
    );

    // Check if the filter has any refinements available
    let allHidden = true;
    Array.from(filter_item).forEach((el) => {
      el.querySelectorAll('.ais-Panel-body__refinement').length === 0
        ? el.querySelector('.ais-Panel').classList.add('hidden')
        : (allHidden = false);
    });

    // Display no results message
    allHidden
      ? filter
          .querySelector('.loopui-usearch__no-results-message')
          .classList.add('visible')
      : filter
          .querySelector('.loopui-usearch__no-results-message')
          .classList.remove('visible');
  });
};

const closeSearchBox = () => {
  const searchBoxFilter = document.querySelector(
    '#searchbox-filter .loopui-usearch__current-refinements--item',
  );
  if (searchBoxFilter && searchBoxFilter.textContent.length) {
    document.querySelector('.ais-SearchBox-reset').click();
  }
};

const updateFiltersVisibility = () => {
  const wrapper = document.getElementById('current-filters-wrapper');
  const badge = document.getElementById('badge-container');

  // 1) get wrapper free width
  //const wrapperWidth = wrapper.clientWidth;
  const wrapperWidth = 500;
  let occupiedWidth = badge.classList.contains('hidden')
    ? 0
    : badge.offsetWidth;

  Array.from(wrapper.children).forEach((el) => {
    occupiedWidth += el.offsetWidth;
    el.dataset.original_width = el.offsetWidth;

    // 2) check if next element fits wrapper
    if (wrapperWidth < occupiedWidth) {
      // 3) if doesnt fit, display badge and append element
      badge.classList.remove('hidden');
      badge.querySelector('.badge-tooltip').appendChild(el);

      // 4) set occupied width to badge width
      let tmpOccupiedWidth = 0;
      Array.from(wrapper.children).forEach((el) => {
        tmpOccupiedWidth += el.offsetWidth;
      });
      occupiedWidth = tmpOccupiedWidth + badge.offsetWidth;
    }
  });

  // Check if there is free space to move an element to the outter wrapper
  if (occupiedWidth < wrapperWidth) {
    Array.from(badge.querySelector('.badge-tooltip').children).forEach(
      (el) => {
        if (el.dataset.offset_width < wrapperWidth - occupiedWidth) {
          wrapper.appendChild(el);
        }
      },
    );
  }

  //  5) Check if a filter was removed from badge tooltip and is now empty
  Array.from(badge.querySelector('.badge-tooltip').children).forEach(
    (filter) => {
      if (
        !filter.querySelectorAll(
          '.loopui-usearch__current-refinements--item span',
        ).length
      ) {
        wrapper.appendChild(filter);
      }
    },
  );

  // 6) update badge container counter
  const badgeItems = badge.querySelectorAll(
    '.loopui-usearch__current-refinements--item span',
  ).length;
  badge.querySelector('span').textContent = `+${badgeItems}`;

  if (badgeItems === 0) {
    badge.classList.add('hidden');
  }
};

const counterFilters = () => {
  document.getElementById('filters-counter').textContent = `(${
    document.querySelectorAll(
      '.loopui-usearch__current-refinements--item span',
    ).length ?? 0
  })`;
};

const updateFilters = () => {
  updateFiltersVisibility();
  counterFilters();
  const hasFilters = document.querySelectorAll(
    '.loopui-usearch__current-refinements--item span',
  ).length > 0;
  hasFilters ?
    document.querySelector('#clear-refinements button').classList.remove('loopui-usearch__footer--refinements--disabled') :
    document.querySelector('#clear-refinements button').classList.add('loopui-usearch__footer--refinements--disabled');
}

window.onload = () => {
  document.querySelector('#clear-refinements button').addEventListener('click', () => {
    // Remove all elements from badge tooltip
    const tmpStoredElements = document.querySelector('.badge-tooltip').children;
    if (tmpStoredElements.length) {
      const wrapper = document.querySelector('#current-filters-wrapper');
      Array.from(tmpStoredElements).forEach(el => wrapper.appendChild(el))
    }

    // Refine applied filters
    appliedFilters.forEach((filter) => filter.refine(filter.value));

    // Clear searchbox
    closeSearchBox();

    // Clear datetime picker
    const clearDatePicker = document.querySelector(
      '.datepicker--container i',
    );
    if (clearDatePicker !== null) {
      clearDatePicker.click();
    }

    // Close all opened filters dropdown
    [
      ...document.querySelectorAll(
        '.loopui-usearch__advanced-search__filters--list',
      ),
    ].forEach((list) => {
      list.classList.remove('hidden');
    });

    // Reset global search view
    const resultsContainer = document.querySelector('.loopui-usearch__results');
    resultsContainer.classList.add('overflow-results');
    Array.from(resultsContainer.children).forEach(el => el.className = '');

    // Select tab 'All'
    const tabsContainer = document.querySelector('.loopui-usearch__tabs-results--container');
    tabsContainer.classList.remove('show-results');
    [...document.querySelector('.loopui-usearch__tabs').children].forEach((el,index) => {
      index === 0 ?
        el.classList.add('selected-tab')
      : el.classList.remove('selected-tab')
    });

    // Clear arrays
    storedFilters.splice(0, storedFilters.length);
    appliedFilters.splice(0, appliedFilters.length);
  });

  // Check if refinement lists are empty
  checkNoRefinements();

  // close modal if click outside of modal wrapper
  const modal = document.querySelector('.loopui-modal');
  modal?.addEventListener('click', (e) => {
    if (modal === e.target) {
      document.querySelector('.loopui-modal__close').click();
    }
  });

  Array.from(
    document.querySelectorAll(
      '.loopui-usearch__advanced-search__filters--filter button',
    ),
  ).forEach((button) => {
    button.addEventListener('click', async (e) => {
      e.preventDefault();

      if (storedFilters.length) {
        const filters = storedFilters.filter(
          (el) =>
            el.attribute === button.dataset.attribute.replace('_input', ''),
        );

        if (appliedFilters.length) {
          const tmp = appliedFilters.filter(
            (el) =>
              el.attribute === button.dataset.attribute.replace('_input', ''),
          );

          let removeFilters = tmp.filter(
            (appliedFilter) =>
              filters.find(
                (storedFilter) =>
                  storedFilter.container_id === appliedFilter.container_id &&
                  storedFilter.value === appliedFilter.value,
              ) === undefined,
          );

          if (removeFilters.length) {
            removeFilters.forEach((filter) => {
              filter.refine(filter.value);

              const index = appliedFilters.indexOf(filter);
              const storedIndex = storedFilters.indexOf(filter);
              appliedFilters.splice(index, 1);
              storedFilters.splice(storedIndex, 1);
            });
          }

          filters.forEach((filter) => {
            if (!isRefined(filter.index_name, filter.value)) {
              filter.refine(filter.value);
            }
          });
        } else {
          filters.forEach((filter) => {
            if (!isRefined(filter.index_name, filter.value)) {
              filter.refine(filter.value);
            }
          });
        }

        appliedFilters = [...appliedFilters, ...storedFilters].filter(
          (filter, index, self) =>
            index ===
            self.findIndex(
              (el) =>
                el.container_id === filter.container_id &&
                el.value === filter.value,
            ),
        );
        storedFilters = [...appliedFilters];
      } else {
        const applied = appliedFilters.filter(
          (el) =>
            el.attribute === button.dataset.attribute.replace('_input', ''),
        );

        let removeFilters = [];
        applied.forEach((filter) => {
          filter.refine(filter.value);
          removeFilters.push(filter);
        });

        if (removeFilters.length) {
          removeFilters.forEach((filter) => {
            const index = appliedFilters.indexOf(filter);
            appliedFilters.splice(index, 1);
          });
        }
      }

      closeFilterLists();
    });
  });

  // Event listeners to show/hide the tooltip
  const badge = document.getElementById('badge-container');
  badge.addEventListener('mouseenter', () => {
    const tooltip = badge.querySelector('.badge-tooltip');
    tooltip.classList.add('visible');

    // Ensure tooltip is fully visible within the viewport
    const rect = tooltip.getBoundingClientRect();

    // Check if the element is overflowing on the right side
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (rect.right > viewportWidth) {
      tooltip.style.left = "-60px";
        return;
    }

    // Check if the element is overflowing on the left side
    if (rect.left < 0) {
      tooltip.style.left = "50px";
    }
  });

  badge.addEventListener('mouseleave', () => {
    setTimeout(() => {
      if (!badge.querySelector('.badge-tooltip').matches(':hover')) {
        const tooltip = badge.querySelector('.badge-tooltip');
        tooltip.classList.remove('visible');
        tooltip.style.left = '';
      }
    }, 1000);
  });

  badge.querySelector('.badge-tooltip').addEventListener('mouseleave', () => {
    const tooltip = badge.querySelector('.badge-tooltip');
    tooltip.classList.remove('visible');
    tooltip.style.left = '';
  });
};

function sendRequest(query) {
  let base_url = window.location.origin;
  let endpointURL =
    base_url + '/loopos_universal_search/api_search?query=' + query; // Corrected variable name

  fetch(endpointURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      // Handle the response data here
      console.log(data);
    })
    .catch((error) => {
      // Handle errors here
      console.error('There was a problem with the fetch operation:', error);
    });
}
