import { Controller } from 'stimulus';
import Bee from '@mailupinc/bee-plugin';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static values = {
    template: Object,
    templateId: String,
    returnUrl: String,
    clientId: String,
    clientSecret: String,
    uid: String
  };

  connect() {
    const clientId = this.clientIdValue;
    const clientSecret = this.clientSecretValue;
    const beeConfig = {
      uid: this.uidValue,
      container: 'bee-plugin-container',
      onSave: async (jsonFile, htmlFile) => {
        await this.onSave(jsonFile, htmlFile);
      },
      onError: (errorMessage) => {
        console.log('onError ', errorMessage);
      },
    };

    const template = this.templateValue;
    const beeTest = new Bee();

    beeTest.getToken(clientId, clientSecret)
      .then(() => beeTest.start(beeConfig, template));
  }

  async onSave(jsonFile, htmlFile) {
    let response = await patch(`/templates/${this.templateIdValue}`, {
      body: JSON.stringify({
        template_html: htmlFile,
        template_json: jsonFile,
      }),
    });

    if (response.response.ok) {
      window.location.replace(this.returnUrlValue);
    }
  }
}
