import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["link"]

  connect() {
    this.linkTarget.click()
  }

}
