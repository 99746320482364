import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['releaseSelector', 'board', 'boardTitle', 'boardList', 'boardTemplate', 'submit'];

  connect() {
    this.boardTarget.closest('[data-modal-target="modal"]').addEventListener("modal-close", this.clean.bind(this))
    if (this.hasReleaseSelectorTarget) {
      const controller = this
      this.releaseSelectorTarget?.addEventListener("release-changed", controller.displayMigrations.bind(controller))
    }
  }

  async displayMigrations(event) {
    const releaseId = event?.detail?.value?.id
    if (!this.hasBoardTarget || !this.hasBoardListTarget) return

    const massAssignElement = document.querySelector("[data-controller='mass-assign']")
    const massAssignController = this.application.getControllerForElementAndIdentifier(massAssignElement, 'mass-assign');
    
    if (releaseId && massAssignController) {
      if (this.hasSubmitTarget) this.submitTarget.querySelector("button").disabled = true
      const appInstanceIds = massAssignController.selectedIds
      const [success, migrations, message] = await this.getMigrations(appInstanceIds, releaseId)

      this.boardTitleTarget.innerHTML = message
      this.boardListTarget.querySelectorAll("li").forEach((element) => element != this.boardTemplateTarget && element.remove())

      if (success) {
        const template = this.boardTemplateTarget
        migrations.forEach((migration) => {
          const newItem = template.cloneNode(true)
          newItem.innerHTML = migration
          newItem.removeAttribute("data-app-instance-migration-target")
          this.boardListTarget.append(newItem)
        })
        this.submitTarget.querySelector("button").disabled = false
      }
      this.boardListTarget.classList.remove("hidden")
    }
  }

  async getMigrations(appInstanceIds, releaseId) {
    if (!appInstanceIds || !releaseId) {
      return;
    }

    const url = new URL(window.location.origin + '/admin/app_management/app_instances/migrations')
    url.searchParams.set("release_id", releaseId)
    appInstanceIds.forEach((appInstanceId) => {
      url.searchParams.append("app_instance_ids[]", appInstanceId)
    })
    const response = await fetch(url.href)
    if (response.ok) {
      const parsedResponse = await response.json()
      return [parsedResponse?.success, parsedResponse?.migrations || [], parsedResponse?.message]
    } else {
      return [false, [], "Error connecting to manager."]
    }
  }

  clean() {
    const submitButton = this.submitTarget?.querySelector("button")
    if (submitButton) submitButton.disabled = true
    this.boardListTarget.classList.add("hidden")
    this.boardTitleTarget.innerHTML = ""
  }
}
