import { FormProps as OriginalFormProps, withTheme } from '@rjsf/core'
import { FormContextType, RJSFSchema, StrictRJSFSchema } from '@rjsf/utils'
import { ComponentType } from 'react'
import { generateTheme } from '../Theme'

interface CustomFormProps<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>
  extends OriginalFormProps<T, S, F> {
  app?: any
}

export function generateForm<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(): ComponentType<CustomFormProps<T, S, F>> {
  return withTheme<T, S, F>(generateTheme<T, S, F>())
}

export default generateForm()
