import * as React from 'react'
import { ChangeEvent, FocusEvent } from 'react'
import {
  ariaDescribedByIds,
  enumOptionsIndexForValue,
  enumOptionsValueForIndex,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from '@rjsf/utils'
import { Select } from '@theloopco/loopos-ds-sdk'

export default function SelectWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  schema,
  id,
  options,
  required,
  disabled,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  rawErrors = [],
  app,
  ...props
}: WidgetProps<T, S, F>) {
  const { enumOptions, enumDisabled, emptyValue: optEmptyValue } = options

  function handleSelect(selectedOption) {
    const newValue = multiple ? selectedOption.map((option) => option.id) : selectedOption.id

    const formattedValue = enumOptionsValueForIndex<S>(newValue, enumOptions, optEmptyValue)

    onChange(formattedValue)
  }

  const mappedOptions = () => {
    const mappedOp = enumOptions?.map((option, index) => ({
      label: option.label,
      value: option.value,
      id: index.toString(),
    }))

    if (!required && mappedOp) {
      mappedOp.unshift({
        label: '-',
        value: '',
        id: 'empty',
      })
    }

    return mappedOp || []
  }
  const selectedOption = mappedOptions().find((option) => option.value == value)

  return (
    <div className="w-full">
      <Select
        options={mappedOptions()}
        handleSelect={handleSelect}
        multiple={multiple}
        size="medium"
        app={props?.registry?.formContext?.app}
        icon="fa-regular fa-chevron-down"
        placeholder={selectedOption?.label || '-'}
        defaultValue={value}
        isDisabled={disabled || readonly}
        isSearchable={true}
      />
    </div>
  )
}
