import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ariaDescribedByIds,
  descriptionId,
  FormContextType,
  getTemplate,
  labelValue,
  RJSFSchema,
  schemaRequiresTrueValue,
  StrictRJSFSchema,
  WidgetProps,
} from "@rjsf/utils";
import { ButtonNew, Editor } from "@theloopco/loopos-ds-sdk";

export default function HtmlWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  schema,
  id,
  options,
  required,
  disabled,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  app,
  placeholder,
  registry,
  uiSchema,
  hideLabel,
  label,
  description,
  rawErrors = [],
}: WidgetProps<T, S, F>) {
  const DescriptionFieldTemplate = getTemplate<
    "DescriptionFieldTemplate",
    T,
    S,
    F
  >("DescriptionFieldTemplate", registry, options);

  const [generatedCode, setGeneratedCode] = useState(value);

  useEffect(() => {
    onChange(generatedCode);
  }, [generatedCode]);

  return (
    <div
      className={`relative ${
        disabled || readonly ? "cursor-not-allowed opacity-50" : ""
      }`}
      aria-describedby={ariaDescribedByIds<T>(id)}
    >
      {!hideLabel && !!description && (
        <DescriptionFieldTemplate
          id={descriptionId<T>(id)}
          description={description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <Editor
        previousCode={value}
        setGeneratedCode={setGeneratedCode}
        app={registry.formContext.app}
        iconRails
      ></Editor>
    </div>
  );
}
